import React, { useState, useEffect } from 'react';
import './ClassForm.scss';
import axios from 'axios';
import localStore from './../../store';
import { useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const ClassForm = () => {
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(true); 
  const { token } = localStore();
  const { id } = useParams();
  const [answers, setAnswers] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`https://app-klevai-forms-api-prod.azurewebsites.net/class-forms/` + id, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        setPost(res.data);
      })
      .catch((err) => {
        if (err.response.status == 401){
          localStorage.removeItem('token');
          window.location.href = '/';
        }
      })
      .finally(() => setLoading(false)); 
  }, [id, token]);

  const handleOptionChange = (questionId, optionId) => {
    setAnswers((prev) => ({ ...prev, [questionId]: optionId }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isFormCompleted = post.questions.every((question) => answers[question.id]);

    if (!isFormCompleted) {
      toast.error('Minden kérdésre kötelező válaszolni!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      // return;
    }

    let answersDto = [];
    Object.keys(answers).forEach((key) => {
      answersDto.push({ questionId: key, optionId: answers[key] });
    });

    fetch(`https://app-klevai-forms-api-prod.azurewebsites.net/class-forms/` + id, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ answers: answersDto })
    })
      .then((response) => {
        if (response.status === 200) {
          navigate('/');
        }
      })
      .catch((error) => { 
        if (error.response.status == 401){
          localStorage.removeItem('token');
          window.location.href = '/';
        }
        console.error(error);
        // toast.error('Error submitting form!');
      });
  };

  if (loading) {
    return <div className="container">
      <div className='loading'>Betöltés...</div>
    </div>;
  }

  return (
    <div className="details">
      <div className="container">       
        <form>
          <div key={post.assignmentId}>
            <h1>
              <Link className='back' to="/"><AiOutlineArrowLeft/></Link>{post.subject} <i>({post.teacher})</i>
            </h1>
            <p>
              Kérlek, gondold végig, hogy az alább felsorolt állítások közül melyik milyen mértékben igaz tanárodra. 
              Válaszd ki a megadott skálán a véleményed tükröző értéket 1 és 5 között <i>(1 – egyáltalán nem igaz; 2 – 
              többnyire nincs így; 3 – általában igaz; 4 – többségében így van; 5 – teljes mértékben így van).</i>
            </p>
            <p>Őszinte válaszaidat köszönjük.</p>
            <ul>
              {post.questions &&
                post.questions.map((question) => (
                  <li key={question.id}>
                    <h3>{question.text}</h3>
                    {question.options && question.options.map((option) => (
                      <div key={option.id}>
                        <input
                          type="radio"
                          name={`question-${question.id}`}
                          value={option.id}
                          checked={answers[question.id] === option.id}
                          onChange={() => handleOptionChange(question.id, option.id)}
                        />{' '}
                        {option.text}
                        <br />
                      </div>
                    ))}
                  </li>
                ))}
            </ul>
            <button type="button" onClick={handleSubmit}>
              Küldés
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  )
}

export default ClassForm;