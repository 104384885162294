import React from 'react'
import './footer.scss'

const Footer = () => {
  return (
    <div className="footer">
      <div className='container'>
        <div className='copy'>
          &copy; Copyright Kossuth Lajos Evangélikus Óvoda, Általános Iskola és Gimnázium és Pedagógiai Szakgimnázium {new Date().getFullYear()}.
        </div>
      </div>
    </div>
  )
}

export default Footer