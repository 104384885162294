import React from 'react';
import { BrowserRouter } from "react-router-dom"

import ReactDOM from 'react-dom/client';
import { Route, Routes} from "react-router-dom"
import Layout from './pages/Layout/Layout';
import FrontPage from './pages/FrontPage/FrontPage';
import ClassForm from './pages/ClassForm/ClassForm';
import NoPage from './pages/NoPage/NoPage';
import Login from "./pages/Login/Login";
import localStore from './store';

import './index.scss';

export default function App() {
  const { token, setToken } = localStore();

  if(token) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<FrontPage />}/>
            <Route path="/class-forms/:id" element={<ClassForm />}/>
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
    }
  else{      
    return <Login setToken={setToken} />
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);