import React, { useState, useEffect } from 'react'
import { BiCheckCircle } from 'react-icons/bi'
import { TfiAngleRight } from 'react-icons/tfi';
import axios from 'axios'
import { Link } from 'react-router-dom'
import localStore from './../../store';
import './frontPage.scss'

const Frontpage = () => {
    const [posts, setPosts] = useState([])
    const { token } = localStore();

    useEffect(() => {
      axios.get(
        'https://app-klevai-forms-api-prod.azurewebsites.net/class-forms',
        { headers: { "Authorization": `Bearer ${token}` } }
      ).then(res => {
        const sortedPosts = res.data.sort((a, b) => {
          if (a.isDone === 'waiting' && b.isDone !== 'waiting') {
            return -1;
          } else if (a.isDone !== 'waiting' && b.isDone === 'waiting') {
            return 1;
          } else {
            return 0;
          }
        });
        setPosts(sortedPosts);
      }).catch(err => {
        if (err.response.status == 401){
          localStorage.removeItem('token');
          window.location.href = '/';
        }
      });
    }, [token])  //figyelmeztetés eltűntetése, lehet, hogy rossz
    
    return (
        <div className='frontpage'>
          <div className='container'>
            {posts.map(post => (
              // <Link className='hyperlink' to={!post.isDone ? "/class-forms/" + post.assignmentId : "#"} key={post.assignmentId}>
              <Link className={`hyperlink${post.isDone ? ' done' : ' waiting'}`} to={!post.isDone ? "/class-forms/" + post.assignmentId : "#"} key={post.assignmentId}>
                <div className="flex-container">
                  <div><span className='subject'>{post.subject}</span> <i>({post.teacher})</i></div>
                  <div>{!post.isDone ? <TfiAngleRight className='wrong' /> : <BiCheckCircle className='correct' />}</div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      );
}

export default Frontpage