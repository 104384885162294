import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import "./login.scss";

async function loginUser(credentials) {
    return fetch('https://app-klevai-forms-api-prod.azurewebsites.net/account/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(data => data.json())
}

export default function Login({ setToken }) {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [buttonClass, setButtonClass] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();

        if (!username || !password) {
            toast.error('Felhasználónév és jelszó megadása kötelező!', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
        else {
            setLoading(true);
            setDisableButton(true);
            setButtonClass('disabled');
            const result = await loginUser({ username, password });
            setLoading(false);
            setDisableButton(false);
            setButtonClass('');
            if (result.token) {
                localStorage.setItem('username', username);
                setToken(result.token);
                window.location.href = '/';
            }
            else {
                toast.error('Felhasználónév és jelszó nem megfelelő!', {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }
    };

    return (
        <div className="page">
            <div className="container">
                <div className="login">
                    <img src="https://klevai-forms-dashboard.pages.dev/images/logo.png" alt="" />
                    <h1>KLEVAI Kérdőív</h1>
                    <p>Bejelentkezés moodle felhasználóval!</p>
                    <div className="panel">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Felhasználónév" onChange={e => setUserName(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Jelszó" onChange={e => setPassword(e.target.value)} />
                            </div>
                            <div className="button-container">
                                <button disabled={loading || disableButton} className={buttonClass}>
                                    <div className={loading ? 'loader' : ''}>{loading ? '' : 'Bejelentkezés'}</div>
                                </button>
                                {loading && <div className="progress-bar" />}
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer />

            </div>
            <div className="footer">
                <div className='container'>
                    <div className='copy'>
                        &copy; Copyright Kossuth Lajos Evangélikus Óvoda, Általános Iskola és Gimnázium és Pedagógiai Szakgimnázium {new Date().getFullYear()}.
                    </div>
                    <div className='menu'>
                        <a href="https://klevai-kerdoiv-dashboard.vwsites.net/">Adminisztráció</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}