import React from 'react'
import { Link } from 'react-router-dom'
import './NoPage.scss'

const NoPage = () => {
    return (
        <div className='page-404'>
            <div className="nopage">
                <h1>404-es hiba</h1>
                <p>A keresett oldal sajnos nem található.</p>
                <Link to='/'>Kezdőlap</Link>
            </div>
        </div>
    )
}

export default NoPage