import React, { useEffect, useState, useRef } from 'react';
import './header.scss';
import { TfiAngleDown } from 'react-icons/tfi';
import { Link } from 'react-router-dom';

const Header = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const username = localStorage.getItem('username');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <header className="header">
      <div className="container">
        <nav>
          <ul>
            <li>
              <a href="/">
                <img src="https://www.klevai.hu/wp/wp-content/uploads/2019/02/logo-sz%C3%ADnes.png" alt="" />
              </a>
            </li>
            <li>
              <h1 className='desktop__title'>Kossuth Lajos Evangélikus Óvoda, <br /> Általános Iskola és Gimnázium és Pedagógiai Szakgimnázium</h1>
              <h1 className='mobile__title'>Klevai</h1>
            </li>
          </ul>
          <div onClick={() => setOpen(!open)} className="user" ref={ref}>
          {username}<TfiAngleDown />
          </div>
        </nav>
        <div className={`dropdown-menu ${open ? 'active' : 'inactive'}`} ref={ref}>
          <ul>
            <DropdownItem />
          </ul>
        </div>
      </div>
    </header>
  );
};

function logout() {
  localStorage.removeItem('token');
  window.location.href = '/';
}

function DropdownItem() {
  return (
    <li className="dropdownItem">
      <Link onClick={logout} to="/">
        Kijelentkezés
      </Link>
    </li>
  );
}

export default Header;